<template>
    <div class="content">
        <!-- 一级菜单下面所拥有的二级菜单 -->
        <el-aside class="right_aside">
            <SideMenu :itemList="itemList"></SideMenu>
        </el-aside>
        <!-- 以及二级菜单所对应的页面 -->
        <div class="left_mian">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import SideMenu from '@/components/sidemenu/SideMenu';
export default {
    components: {
        SideMenu
    },
    data() {
        return {
            itemList: [
                {
                    path: 'userRegister',
                    title: '用户新增'
                },
                {
                    path: 'userInfo',
                    title: '用户信息管理'
                }
            ]
        };
    }
};
</script>
<style scoped lang = 'scss'>
.content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.right_aside {
    width: 300px;
    height: 100%;
}
.left_mian {
    overflow: hidden;
    width: 100%;
    height: 100%;
}
::v-deep .right_aside .sidebar .el-submenu__icon-arrow .el-icon-arrow-down {
    display: none;
    content: '';
}
</style>